<script setup lang="ts">
const props = defineProps<{
  modelValue?: string
  value?: string
  placeholder?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | undefined): void
  (e: 'change', value: string | undefined): void
}>()

// Fixme: copied from CKEEditor.vue
interface EditorConfig {
  [key: string]: string | object
}

const editorConfig: EditorConfig = {
  toolbar: {
    items: [
      'heading',
      'bold',
      'italic',
      '|',
      'link',
      'bulletedList',
      'numberedList',
    ],
  },
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
    ],
  },
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
  },
  placeholder: props.placeholder as string,
}

// console.log(`----`)
// console.log(`initial props`, props)
// console.log(`initial attrs`, useAttrs())

// Make sure to initialize the value with either the modelValue or value prop.
// v-bind="field" sets the `value` prop, while v-model sets the `modelValue` prop.
const value = ref<string | undefined>(props.modelValue || props.value)

// When onChange or onInput, we're asuming v-bind="field" is used, so we don't
// want to clash with v-model.
// I believe we're not actually using this on BaseRichText, so we could also comment it out.
const isUsingVbind = !!useAttrs().onChange || !!useAttrs().onInput

const isInitialEmit = ref(true)

watch(value, (val) => {
  // This is a terrible fix to make sure the initial value is not emitted.
  // Which caused form fields using this field being "touched" initially.
  if (val === props.modelValue && isInitialEmit.value) {
    // Make sure the next time we the user enters this value, we do emit it.
    isInitialEmit.value = false
    return
  }
  // Anything after the first emit is not the initial emit.
  // (just to be sure)
  isInitialEmit.value = false

  if (isUsingVbind) {
    // console.log(`Emit change => `, val)
    emit('change', val)
  }
  else {
    // console.log(`Emit update:modelValue => `, val)
    emit('update:modelValue', val)
  }
})
</script>

<template>
  <div>
    <div class="ck-body-wrapper relative">
      <ClientOnly fallback-tag="span" fallback="">
        <CKEditor v-model="value" :editor-config="editorConfig" />
      </ClientOnly>
    </div>
  </div>
</template>

<style>
/* Fix link menu in modal: https://devs.keenthemes.com/question/ck-editor-5-link-url-not-focussing-when-inside-modal */
.ck-body-wrapper {
  z-index: 1300 !important;
}
/* It looks like ckeditor doesn't calculate the position in a modal properly. Therefore I decided to overwrite it.  */
.ck-balloon-panel_visible {
  top: 50px !important;
  left: 200px !important;
}
</style>

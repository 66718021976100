import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ShiftEnter from '@ckeditor/ckeditor5-enter/src/shiftenter'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { AutoLink, Link } from '@ckeditor/ckeditor5-link'
import { DocumentList } from '@ckeditor/ckeditor5-list'
import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm'

class Editor extends ClassicEditor {
  public static override builtinPlugins = [
    Bold,
    Heading,
    Italic,
    Link,
    DocumentList,
    Link,
    AutoLink,
    Markdown,
    ShiftEnter,
  ]
}

export default Editor

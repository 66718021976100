<script lang="ts" setup>
import Ckeditor from '@ckeditor/ckeditor5-vue'

import ClassicEditor from '~/utils/ckeditor'

export interface EditorConfig {
  [key: string]: string | object
}
defineProps<{
  editorConfig: EditorConfig
}>()

const Editor = Ckeditor.component
</script>

<template>
  <Editor :editor="ClassicEditor" :config="editorConfig" />
</template>

<style>
/* Main container */
.ck-editor__editable_inline {
  min-height: 250px;
  color: black;
}

/* The branding of the library */
.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon {
  display: none;
}

.ck-editor__editable :where(h2):not(:where([class~='not-prose'] *)) {
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.ck-editor__editable :where(h1):not(:where([class~='not-prose'] *)) {
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.ck-editor__editable :where(h3):not(:where([class~='not-prose'] *)) {
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.ck-editor__editable
  :where(h2 + *, h1 + *, h3 + *):not(:where([class~='not-prose'] *)) {
  margin-top: 0;
}

.ck-editor__editable :where(p):not(:where([class~='not-prose'] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.ck-editor__editable :where(ul, ol):not(:where([class~='not-prose'] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
}

.ck-editor__editable :where(ul > li):not(:where([class~='not-prose'] *)) {
  padding-left: 0.375em;
}

.ck-editor__editable :where(li):not(:where([class~='not-prose'] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.ck-editor__editable
  :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~='not-prose'] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/* we are setting the z-index of these because their original z-index is higher than the top navbar so it floats over it if the page scrolls */
.ck.ck-sticky-panel > .ck.ck-sticky-panel__content,
.ck.ck-balloon-panel {
  z-index: 10;
}

/* Link styling */
.ck-editor__editable_inline a {
  text-decoration: underline;
  color: blue;
}

/* Placeholder styling */
.ck-placeholder {
  @apply text-muted-300 opacity-30;
}
</style>
